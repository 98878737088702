import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Footer from '../component/Footer'; 

@withRouter
@inject("store")
@observer
export default class PromoLanding extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    componentDidMount()
    {
        if( this.props.match.params.promoid )
        {
            window.localStorage.setItem("KT_PROMOCODE" , this.props.match.params.promoid);
            this.props.history.replace("/course");
        } 
    }

    render()
    {
        const main = <div>PromoLanding
            <Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}