import { observable, action } from "mobx";
import axios from 'axios';
import { toast, isWechat } from '../util/Function';

Array.prototype.unique = function() 
{
    return this.filter(function (value, index, self) { 
      return self.indexOf(value) === index;
    });
}

const APP_VAR_PREFIX = 'FT_VAR_';

class FtApi
{
    constructor( store )
    {
        this.store = store;
    }

    // get 方法不带 token 参数，因为这样不安全
    async get( path , data = null )
    {
        const ds = path.indexOf('?') === -1 ? '?' : '&';
        const url =  data == null ? this.store.apibase + path : this.store.apibase + path + ds + new URLSearchParams( data ).toString(); 
        return this.return_data(await axios.get( url ));
    }

    // post 自动附带 token
    async post( path , data = null, with_token =true, login_alert = true )
    {
        var params = new URLSearchParams();
        if( data )
            Object.keys( data ).forEach( item => params.append(item, data[item]) );

        if( with_token && this.store.token )
            params.append("token", this.store.token);
        
        return this.return_data(await axios.post( this.store.apibase + path  , params ) , login_alert );
    }

    async upload( path , data = null, with_token =true, login_alert = true )
    {
        var params = new FormData();
        if( data )
            Object.keys( data ).forEach( item => params.append(item, data[item]) );

        if( with_token && this.store.token )
            params.append("token", this.store.token);
        
        return await this.return_data(await axios.post( this.store.apibase + path  , params ) , login_alert );
    }

    async return_data( ret , alert = true )
    {
        if( parseInt(ret.status) == 200 )
        {
            if( ret.data  )
            {
                if( ret.data.code )
                {
                    // 如果是授权错误
                    // NOTLOGIN 
                    if( ret.data.code == 20001 )
                    {
                        window.localStorage.removeItem(APP_VAR_PREFIX+"token");
                        if( alert )
                        {
                            if( window.confirm( "需要登入后继续，是否登入?" ))
                            {
                                const url = await this.store.get_weauth_url();
                                // console.log( url );

                                // 避免在 ipad 上无法登入，这里改为直接转向
                                window.location = url;

                                // if( window.innerWidth < 800 )
                                // {
                                //     window.location = url;
                                // }
                                // else
                                // {
                                //     window.open( url , 'ft_weauth' , 'width=500,height=600' );
                                // }

                                
                            } 
                        }
                        
                    }
                    if( ret.data.code == 0 ) return ret.data.data;
                    toast(ret.data.message);
                    console.log("请求错误",ret.data);
                    return false;
                } 
                else 
                    return ret.data;
            }

            return false;
        }
        return false;  
    }
}

class AppState
{
    @observable appname = "方糖完免课✨";    
    
    // 不同的通知内容只显示一次
    @observable float_notice_content = "";    
    @observable float_notice_link = "/login";   

    @observable apibase = 'https://mianapi.ftqq.com/'; 
    //@observable apibase = 'http://this.ftqq.com:8123/'; 
    @observable the_share_text = ''; 

    // 订阅模式
    // ---------------
    // 此模式下不显示课程价格
    @observable subcribe_mode = false;

    @observable menu = [
        {text:"课程",link:"/course"},
        // {text:"路线图",link:"/roadmap"},
        // {text:"作品展示",link:"/works.post"}
    ];

    @observable user = false; 
    @observable token = false; 
    @observable course_to_buy = []; 
    @observable course_buy_info = {}; 

    
    auth_state_code = 0;
    to_save_vars = ['token','user','course_to_buy'];

    
    constructor()
    {
        if( this.subcribe_mode )
        {
            this.menu.push( {text:"订阅",link:"/subscribe"});
            this.menu.push( {text:"最近学习",link:"/recent"});
        }
        else
        {
            this.menu.push( {text:"学习进度",link:"/process"});
            this.menu.push( {text:"已购",link:"/mylist"});      
        }
        
        this.api = new FtApi( this );
        this.load_vars();
    }

    load_vars()
    {
        this.to_save_vars.forEach( v =>
        {
            const ret = window.localStorage.getItem(APP_VAR_PREFIX+v);
            if( ret ) this[v] = JSON.parse( ret );
        });
    }

    save_vars( field = null )
    {
        const vars_array = field === null ? this.to_save_vars : [field];
        vars_array.forEach( v =>
        {
            window.localStorage.setItem(APP_VAR_PREFIX+v, JSON.stringify( this[v] ));
        });
    }

    @action to_buy_course( course_id )
    {
        this.course_to_buy.unshift( course_id );
        this.course_to_buy = this.course_to_buy.unique();
        this.save_vars();
        window.location = '/cart';
    }
    
    @action cancel_course( course_id )
    {
        this.course_to_buy = this.course_to_buy.filter( item => item != course_id );
        this.save_vars();
    }

    @action clean_cart()
    {
        this.course_to_buy = [];
        this.course_buy_info = {};
    }

    get_buy_total_price()
    {
        let total = 0;
        this.course_to_buy.unique().forEach( course_id =>
        {
            if( this.course_buy_info[course_id] && this.course_buy_info[course_id].price_cent )
            total += parseInt(this.course_buy_info[course_id].price_cent);
        })
        return total;
    }

    @action set_token( token )
    {
        this.token = token;
        this.save_vars('token');
    }

    async get_weauth_url()
    {
        const { data } = await this.api.get( "auth/url" );
        return data;
    }

    async get_mplogin_url()
    {
        const { data } = await this.api.get( "mplogin/url" );
        return data;
    }

    async login_by_mpcode( code )
    {
        const user = await this.get_user_info_by_mpcode(code);
        if( user && user.token )
            return this.login_by_user( user );
        else
            return false;    
    }

    @action login_by_user( user )
    {
        if( user && user.token )
        {
            this.user = user;
            this.token = user.token;
            this.save_vars();
            return user.token;
        }
        else
        {
            // window.alert("登入失败，请重试");
            console.log( "error",user );
            return false;
        }
    }

    async get_user_info_by_mpcode( code )
    {
        const { data } = await this.api.post( "mp/code2token" , {code} );
        return data;
    }

    async get_access_token( code )
    {
        
        const user = await this.get_user_info(code);
        return user.token ;
    }

    @action async login_by_code( code )
    {
        const user = await this.get_user_info(code);
        if( user && user.token )
        {
            this.user = user;
            this.token = user.token;
            this.save_vars();
            return user.token;
        }
        else
        {
            // window.alert("登入失败，请重试");
            console.log( "error",user );
            return false;
        }
    }

    async get_user_info( code )
    {
        const { data } = await this.api.post( "auth/code2token" , {code} );
        return data;
    }

    async get_course_list( cate = null )
    {
        if( cate == null || cate == undefined )
        {
            cate = "";
        } 
        
        // 需要登入后获取的数据全部用 post
        const { data } = await this.api.post("course/public/list", {cate}, true, false);
        return data;
        
    }

    async get_my_course_list( type = 'brought' )
    {
        const { data } = await this.api.post("course/my/list",{type});
        return data;
    }

    async get_recent_course_list()
    {
        const { data } = await this.api.post("course/recent/list");
        return data;
    }

    async get_course_detail( course_id )
    {
        const { data } = await this.api.post( "course/public/detail" , {course_id} );
        return data;
    }

    async get_buy_url()
    {
        let goods_id = [];
        this.course_to_buy.unique().forEach( course_id =>
        {
            if( this.course_buy_info[course_id] && this.course_buy_info[course_id].wa_goods_id )
            goods_id.push( this.course_buy_info[course_id].wa_goods_id );
        })

        const { data } = await this.api.post( "my/buy/url" , {goods_id} );
        return data;
    }

    async sync_order( order_id )
    {
        const { data } = await this.api.post( "order/sync/" , {order_id} );
        return data;
    }

    async get_section_content( section_id )
    {
        const { data } = await this.api.post( "course/section/content" , {section_id} );
        return data;
    }

    async get_course_info()
    {
        const { data } = await this.api.get("course/info");
        return data;
    }

    async get_roadmap_info()
    {
        const { data } = await this.api.get("roadmap/info");
        return data;
    }

    async get_post_detail( route )
    {
        const { data } = await this.api.post( "public/post/detail" , { route } );
        return data;
    }

    async do_subscribe( year )
    {
        const { data } = await this.api.post( "subscribe/pay" , {year} );
        return data;
    }

    @action clean_user()
    {
        this.token = false;   
        this.user = false;
        this.save_vars();   
    }

    @action set_user( user )
    {
        if( user && user.token )
        {
            this.user = user;
            this.token = user.token;
            this.save_vars();
            // return user.token;
        }
    }

    async refresh_user()
    {
        const user = await this.get_self_info();
        this.set_user( user );
        return this.user;
    }

    // data = null, with_token =true, login_alert = true
    async get_self_info()
    {
        const { data } = await this.api.post( "user/self" , null, true, false );
        return data;
    }

    @observable the_subscribe_year = "1";
    @observable the_subscribe_options = [
        {"value":"1","name":"订阅1年"},
        {"value":"2","name":"订阅2年"},
        {"value":"3","name":"订阅3年"},
        {"value":"4","name":"订阅4年"},
        {"value":"5","name":"订阅5年"},
    ];

    @observable the_recharge_number = "";
    @observable the_recharge_code = "";
    @observable the_next_code = "";

    async next_get_coin( next_code )
    {
        const { data } = await this.api.post( "next/code" , { "code":next_code } );
        return data;
    }
    
    async recharge_coin( number, promo_code )
    {
        const { data } = await this.api.post( "card/recharge" , { number, promo_code } );
        return data;
    }

    async create_promo_code()
    {
        const { data } = await this.api.post( "promocode/create" );
        return data;
    }

    async get_cic_key()
    {
        const { data } = await this.api.post( "my/cic/key" );
        return data;
    }

    async set_order( course_id )
    {
        const { data } = await this.api.post( 'set/order' , { course_id , "openid":this.user.openid } );
        return data;
    }

    async send_usage( course_id, section_id, usage_check )
    {
        const { data } = await this.api.post( "usage/send" , {
            course_id, section_id, usage_check
        } );
        return data;
    }

    async check_resoure_download( course_id, uid )
    {
        const { data } = await this.api.post( "resource/check" , {
            course_id,uid
        } );
        return data;
    }

    async get_course_process( course_id )
    {
        const { data } = await this.api.post( "usage/course" , {
            course_id
        } );
        return data;
    }

    async buy_course( course_id )
    {
        const { data } = await this.api.post( "course/pbuy" , {
            course_id
        } );
        return data;
    }

    async refund_course( course_id, type , donate = 1 )
    {
        const { data } = await this.api.post( "course/refund" , {
            course_id,
            type,
            donate
            // 'try':1
        } );
        return data;
    }

    async force_login()
    {
        // 如果没有登入，那么自动登入
        // this.clean_user();
        if( !this.token )
        {
            // 开始登入
            const url = isWechat() ? await this.get_mplogin_url(): await this.get_weauth_url();

            // 写入forward
            const forward = window.location.pathname;
            window.localStorage.setItem( 'forward', forward );

            window.location = url;

            // if( window.innerWidth < 800 )
            //     window.location = url;
            // else
            //     window.open( url , 'ft_weauth' , 'width=500,height=600' );

            return false;
        }
        return true;
    }


    

}

export default new AppState();