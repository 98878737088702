import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Footer from '../component/Footer'; 
import TopNav from '../component/TopNav'; 
import TextLine from '../component/TextLine'; 
import SubmitLine from '../component/SubmitLine'; 
import { toast } from '../util/Function';
import { Button } from '@blueprintjs/core';
import {CopyToClipboard} from 'react-copy-to-clipboard';

@withRouter
@inject("store")
@observer
export default class Recharge extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    async componentDidMount()
    {
        const user = await this.props.store.refresh_user();
        console.log(user);
        if( this.props.store.the_recharge_code?.length < 1 )
        {
            const pcode = window.localStorage.getItem("KT_PROMOCODE");

            if( pcode && pcode != 'null' )
            {
                this.props.store.the_recharge_code = pcode;
            }
        }
        // 充值卡密码
        const password = this.props.match.params.password;
        if( password && this.props.store.the_recharge_number?.length < 1 )  this.props.store.the_recharge_number = password;
        // the_recharge_number
        
    }

    async submit()
    {
        if( this.props.store.the_recharge_number.length < 1 )
        {
            toast("充值卡密码/序号不能为空");
            return false;
        }
        
        if( !window.confirm("方糖币将充值到当前账号，充值后不可撤销，继续？")) return false;

        const ret = await this.props.store.recharge_coin( this.props.store.the_recharge_number , this.props.store.the_recharge_code ); 

        if( ret && ret.coin )
        {
            if( ret.promo && parseInt(ret.promo) > 0 )
            {
                toast("充值完成，充值"+ret.amount+"枚，红包赠送"+ret.promo+"枚");
            }
            else
            {
                toast("充值完成，充值"+ret.amount+"枚");
            }
            
            await this.props.store.refresh_user();
            this.props.store.the_recharge_number = '';
            window.localStorage.setItem("KT_PROMOCODE" , this.props.store.the_recharge_code);
        }


    }

    async getCoin()
    {
        if( this.props.store.the_next_code.length < 1 )
        {
            toast("提取码/课程密码不能为空");
            return false;
        }
        
        if( !window.confirm("提取码只能兑换一次，方糖币将充值到当前账号，充值后不可撤销，继续？")) return false;

        const ret = await this.props.store.next_get_coin( this.props.store.the_next_code );
        
        if( ret && ret.amount )
        {
            toast("领取了"+ret.amount+"枚方糖币");
            await this.props.store.refresh_user();
            
        }
    
    }

    async create()
    {
        // 调用生成方糖红包码接口
        const ret = await this.props.store.create_promo_code();
        if( ret )
        {
            toast("方糖红包码已经创建");
            const user = await this.props.store.refresh_user();
        }  
    }

    

    render()
    {
        const main = <div><TopNav/>
        <div className="page-box">
                <div className="ft-post-page">
                
                <h2 className="rs-title">方糖币余额</h2>
                <div className="whitebox small big-font">
                当前持有方糖币 {this.props.store.user.coin} 枚
                </div>

                <h2 className="rs-title">充值</h2>
                <div className="whitebox small-margin">
                    <TextLine field="the_recharge_number" label="充值卡密码/序号" required={true} placeholder="序号或提取密码"/>
                    <TextLine field="the_recharge_code" label="方糖红包码" placeholder="选填，可以获得额外的随机金额的方糖币"/>
                    <SubmitLine label="充值" onSubmit={()=>this.submit()} right={<Button large="true" onClick={()=>this.props.history.push("/cardshop.post")}>购买充值卡</Button>} />
                </div> 

                <h2 className="rs-title">我的方糖红包码</h2>
                <div className="whitebox small-margin">
                
               {this.props.store.user.promo_code ? <div className="promo-code">
               🎈&nbsp; <span className="code">{this.props.store.user.promo_code}</span><CopyToClipboard text={this.props.store.user.promo_code} onCopy={()=>
                {
                    toast("方糖红包码已复制到剪贴板");
                }}><Button text="复制方糖红包码" large={true} className="mr5" /></CopyToClipboard> 
                <CopyToClipboard text={window.location.origin + '/'+this.props.store.user.promo_code+'.code'} onCopy={()=>
                    {
                        toast("推广网址已复制到剪贴板");
                    }}><Button text="复制带参网址" large={true} className="mr5"/></CopyToClipboard> 
                </div> : <div >
                <Button text="创建方糖红包码" large={true} onClick={()=>this.create()}/>
                </div>}
                <div className="std-hr form-div"></div>
                <ul className="listinbox">
                    <li>充值时输入方糖红包码，可以领取随机额度的方糖币红包</li>
                    <li>方糖红包码的创建人亦将获得同样额度的方糖币</li>
                    <li>充值超过 360 方糖币的用户可以创建方糖红包码</li>
                    <li>使用自己创建的方糖红包码充值不能领取方糖币红包</li>
                </ul>
                
                </div>

                <h2 className="rs-title">Next众筹领币</h2>
                <div className="whitebox small-margin">
                    <TextLine field="the_next_code" label="Next课程提取码/课程密码" required={true} placeholder="课程提取码/课程密码"/>
                    <SubmitLine label="领取" onSubmit={()=>this.getCoin()}/>
                </div> 

                </div>

                
                          
            </div>
        <Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}