import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Footer from '../component/Footer'; 
import TopNav from '../component/TopNav';
import ProcessItem from '../component/ProcessItem'; 
import { Button } from '@blueprintjs/core';

@withRouter
@inject("store")
@observer
export default class ProcessDetail extends Component
{
    state = {"sections":false,"course":false,"all_done":false};
    
    async componentDidMount()
    {
        const course_id = this.props.match.params.id; 
        if( course_id > 0 )
        {
            const data = await this.props.store.get_course_process( course_id );

            if( data )
            {
                this.setState({...data});
            }
            
        }
    }

    async pbuy( course_id )
    {
        if( !window.confirm( "确定要单独购买本课程吗，购买后将不能退款，有效期为365天" )) return false;

        const data = await this.props.store.buy_course( course_id );

        if( data && data.msg == 'done'  )
        {
            this.props.history.push("/mylist");
        }
    }

    async refund( course_id )
    {
        this.props.history.push( "/finish-discount/"+course_id );
        return ;
    }

    render()
    {
        let sections = this.state.sections;
        if( sections ) sections = sections?.sort( ( a, b ) => a.order - b.order )
        const course = this.state.course;
        console.log( sections )
        const main = <div>
            <TopNav/>
            <div className="container ft-notop">
                { !sections ? 
                <div className="notice-box">还没有学习记录呢，<Link to={"/course/detail/"+this.state.course.id}>先去学习吧</Link></div> : <div className="container ">
                    
                    <div className="my-row mt-10">
                        <div className="left">
                        <h3>{course.title}</h3>
                        </div>
                        <div className="right">
                        {this.state.refund_lock != 1 && <>
                            { this.state.all_done != 1 ? <Button text={this.state.check_day+"前学完后点此退款"} disabled={this.state.all_done != 1} large={true} /> : <Button text={this.state.check_day+"前可点此退款"} onClick={()=>this.refund(this.state.course.id)}  large={true} /> }
                        
                        <Button text="单独购买" large={true} intent="primary" onClick={()=>this.pbuy(this.state.course.id)}/>
                        
                        
                        </>}
                        </div>
                        
                    </div>
                    
                    <div>
                    {sections.map( item => <ProcessItem key={item.id} data={item}/> )}
                    </div>
                    

                </div> }    
            </div>    
            <Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}