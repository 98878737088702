import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { Button, Intent, Spinner } from '@blueprintjs/core';
import md5 from 'js-md5';

@withCookies
@withRouter
@inject("store")
@observer
export default class TopNotice extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }

    go()
    {
        const link = this.props.store.float_notice_link;
        if( link )
        {
            if( link.substring(0,4) == 'http' ) window.open( link , "_blank" )
            else
                this.props.history.push(link); 
        }
            
    }
    
    render()
    {
        if( !this.props.store.float_notice_content ) return null;

        const cname = "ft_top_notice_" + md5(this.props.store.float_notice_content);

        const classfix = this.props.store.float_notice_link ? ' pointer ':'';

        return this.props.cookies.get(cname) ? null : <div className={"ft-top-notice " + classfix } onClick={()=>this.go()}>
        <Button icon="cross" className="closebtn" minimal={true} onClick={(e)=>{this.props.cookies.set( cname , true );e.stopPropagation()}} />
        <div className="container flexcenter">{this.props.store.float_notice_content}</div>
        </div> ;
    }
}