import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import * as sd from 'simple-duration';

@withRouter
@inject("store")
@observer
export default class CourseSectionItem extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    go( url )
    {
        // this.props.history.push( url );
        window.location= url;
    }
    
    render()
    {
        const section  = this.props.data;
        if( !section ) return null;
        const  classstr = this.props.isActive ? "active" : "";  

    return <li className={"section-item "+classstr} onClick={()=>this.go('/section/show/'+section.id)}><div className="item-title">{section.title}</div> <span className="duration">{sd.stringify(parseInt(section.duration), "s")}</span></li>;
    }
}