import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import TopNav from '../component/TopNav'; 
import HeroRoadMap from '../component/HeroRoadMap'; 
import RoadMapList from '../component/RoadMapList'; 
import Footer from '../component/Footer';

@withRouter
@inject("store")
@observer
export default class RoadMap extends Component
{
    state = {"info":false};
    
    async componentDidMount()
    {
        const info = await this.props.store.get_roadmap_info();
        if( info )
        {
            this.setState({info});
        }
    }


    render()
    {
        const main = <div>
            <TopNav/>
            {this.state.info.hero && <HeroRoadMap data={this.state.info.hero} />}
            {this.state.info.maps && <RoadMapList data={this.state.info.maps}/>}
            <Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}