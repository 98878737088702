import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Footer from '../component/Footer'; 
import TopNav from '../component/TopNav'; 

@withRouter
@inject("store")
@observer
export default class NoticePage extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    render()
    {
        const main = <div>
            <TopNav/>
            <div className="notice-box"><div style={{padding:"160px"}}>本站已停止新用户和购买，<a href="https://stack.ftqq.com/?mtm_campaign=mian">请访问新版课程网站</a></div></div>
            <Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}