import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { Spinner } from '@blueprintjs/core';

@withRouter
@inject("store")
@observer
export default class Logout extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    componentDidMount()
    {
        this.props.store.clean_user();
        this.props.history.replace("/");
    }

    render()
    {
        return <div className="fullcenter">
        <Spinner/>
    </div>;
    }
}