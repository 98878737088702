import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import RoadMapCard from '../component/RoadMapCard'; 


@withRouter
@inject("store")
@observer
export default class RoadMapList extends Component
{
    
    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        return this.props.data ? <div className="roadmap-list">
        <div className="container">
            { this.props.data.map( item => <RoadMapCard data={item} key={item.id} /> ) }
        </div>
    </div> : null;
    }
}