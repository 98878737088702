import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import CourseChapterItem from '../component/CourseChapterItem'; 

@withRouter
@inject("store")
@observer
export default class CourseChapterList extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        const chapters = Object.values(this.props.data);
        if( !chapters ) return null;

        return <div className="container course-index top50">
            { chapters && chapters.sort( (a , b) => a.order - b.order ).map( item => <CourseChapterItem data={item} key={item.id} active={this.props.active} /> ) }    
        </div>;
    }
}