import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ModalImage from "react-modal-image";
import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/solarized-light.css';
import javascript from 'highlight.js/lib/languages/javascript';
hljs.registerLanguage('javascript', javascript);


function LinkRenderer(props) 
{
    return <a href={props.href} target="_blank">{props.children}</a>
}

function ImageRenderer(props)
{
    return <ModalImage className="markimg" small={props.src} large={props.src} />;
}

@withRouter
@inject("store")
@observer
export default class MyReactMarkdown extends Component
{
    componentDidMount()
    {
        document.querySelectorAll('pre code').forEach((block) => {
            hljs.highlightBlock(block);
          });
    }

    
    render()
    {
        return this.props.source ? <ReactMarkdown className="marktext" source={this.props.source} renderers={{link: LinkRenderer,image:ImageRenderer}} /> : null;
    }
}