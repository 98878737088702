import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import TopNav from '../component/TopNav'; 
import CourseCard from '../component/CourseCard'; 
import Footer from '../component/Footer'; 
@withRouter
@inject("store")
@observer
export default class MyCourse extends Component
{
    state = { "courses":false };
    
    async componentDidMount()
    {
       const courses = await this.props.store.get_my_course_list('brought');
       if( courses )  this.setState({courses});
    }

    render()
    {
        const main = <div>
            <TopNav/>
            { !this.state.courses ? 
            <div className="container ft-notop">
                <div className="notice-box">还没有购买的课程，<Link to="/course">点这里浏览课程</Link>（目前还可以退款的课程在学习进度中查看）</div>
                
            </div>
            : 
                <div className="cate-course-list">
                <div className="container top50">
                    {this.state.courses.map( item =>  <CourseCard key={item.id} data={item} /> )}
                </div></div>
            }
        <Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}