import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Tooltip } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class UserAvatar extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        // console.log( this.props.store.user );
        const avatar = this.props.store.user.headimgurl;
    
        return avatar ? <Tooltip content={this.props.store.user.nickname}><img src={avatar} className="avatar-icon" alt="avatar" /></Tooltip> : null ;
    }
}