import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Menu, MenuItem, MenuDivider, Popover, Position } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class UserMenu extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        const exampleMenu = (
            <Menu>
                {/* <MenuItem icon="settings" text="设置" onClick={()=>this.props.history.push("/settings")} /> */}
                
                {/* {this.props.store.subcribe_mode ? <MenuItem icon="dollar" text="方糖币和充值" onClick={()=>this.props.history.push("/recharge")} /> : <MenuItem icon="shopping-cart" text={"购物车 " + (this.props.store.course_to_buy.length > 0 ? this.props.store.course_to_buy.length : "")} onClick={()=>this.props.history.push("/cart")} />} */}
                
                {/* <MenuItem text={"互动课领取"} icon="airplane" onClick={()=>this.props.history.push("/cic")} /> */}
                

                
                {/* <MenuDivider /> */}
                <MenuItem icon="log-out" text="退出" onClick={()=>this.props.history.push("/logout")} />
            </Menu>
        );
        return  <span className="left-5">{ this.props.store.user && <Popover content={exampleMenu} position={Position.BOTTOM}>
        <Button rightIcon="caret-down" minimal={true} />
        </Popover>}</span>;
    }
}