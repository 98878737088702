import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TextLine from '../component/TextLine';
import SubscribeLine from '../component/SubscribeLine'; 
import { toast } from '../util/Function';
import dayjs from 'dayjs';

@withRouter
@inject("store")
@observer
export default class HeroSubscribeBox extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    async subscribe( year )
    {
        // toast("订阅"+year+"年"); 
        // 开始调用接口
        const ret = await this.props.store.do_subscribe( year );
        if( ret && ret.done )
        {
            toast("您的订阅已续期至"+ dayjs(ret.done).format("YYYY年MM月DD日"));
            await this.props.store.refresh_user();
        }
        // console.log( ret );
    }

    buycard()
    {
        window.open("/cardshop.post","cardshop");
    }
    
    render()
    {
        return <><div className="ft-hero-div">
        <div className="container">
            <div className="hero-box ft-bg-yellow level1">
                <img src="/subscribe.png" alt="订阅" />
            </div> 
        </div>
    </div>
    <div>
        <div className="container">
            <div className="submit-box">
                { (this.props.store.user.first_year_subscribed && this.props.store.user.first_year_subscribed == 1) ? <div className="whitebox small big-font">您的订阅期到 {dayjs(this.props.store.user.subscribe_end_date).format("YYYY年MM月DD日")} 截止</div> : null }
                
                <SubscribeLine 
                    field="the_subscribe_year" 
                    options={this.props.store.the_subscribe_options} coin={this.props.store.user.coin} first_year_subscribed={this.props.store.user.first_year_subscribed == 1}
                    onSubmit={(value)=>this.subscribe(value)}    
                    onBuyCard={()=>this.buycard()}    
                />
            </div>
        </div>
    </div>
    <div className="text">
        <div className="container">
        <div className="subcribe-explain">
                <h3>订阅价格</h3>
                <ol>
                    <li>首年：998方糖币</li>
                    <li>续订：499方糖币/年</li>
                </ol>
                <div className="small">
                    <p>* 1方糖币约等于1人民币，可通过「方糖充值卡」进行充值。</p>
                    <p>* 随着课程的增加，我们会逐渐提升订阅价格。但已经购买的订阅不受此影响。</p>
                    
                </div>
                <h3>课程范围</h3>
                <ol>
                    <li>目前收录了《方糖新全栈》、《变现进阶课》等内容（部分章节有删改）</li>
                    <li>后续将收录实战营课程</li>
                    <li>查看<a href="/comingsoon.post">上新日志</a>，了解课程更新情况</li>
                </ol>
            </div>
        </div>
    </div>
    
    </>;
    }
}