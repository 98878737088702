import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Footer from '../component/Footer'; 
import TopNav from '../component/TopNav'; 
import { toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class OrderCheck extends Component
{
    state = {"notice":false};
    
    async componentDidMount()
    {
        // 订单回调确认页面
        // 首先获取 order_id
        const query = new URLSearchParams(this.props.location.search);
        const order_id =  query.get('order_id');
        // toast("order_id"+order_id);
        // 然后调用接口通过 oauth 接口确认订单信息，并根据信息修改本应用的已购数据
        // 根据接口返回显示提示信息
        const ret = await this.props.store.sync_order( order_id );
        console.log( ret );
        if( ret && ret.message && ret.message == 'done' )
        {
            this.setState({"notice":"订单信息同步完成，请回到浏览器→「已购」中查看"});
        }
        else
        {
            this.setState({"notice":"请至浏览器→「已购」中查看，如有问题可通过页面最下方的「反馈和投诉」联系我们"});
        }
    }

    render()
    {
        const main = <div>
            <TopNav/>
            <div className="container ft-notop">
                {this.state.notice && <h3>{this.state.notice}</h3>}
            </div>
            <Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}