import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import DPlayer from "react-dplayer";
import HlsJs from 'hls.js/dist/hls.light';

@withRouter
@inject("store")
@observer
export default class CourseVideo extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    state = {"data":false};

    componentDidMount()
    {
       this.setState({"data":this.props.data}); 
    }

    componentDidUpdate(prevProps)
    {
        console.log( "new data", this.props.data );
        if (this.props.data !== prevProps.data) 
        {
            this.setState({"data":this.props.data}); 
        }
    }
    
    render()
    {
        if( !this.state.data ) return false;
        const url = this.state.data.video_hls_url && this.state.data.video_hls_url.length > 0 ? this.state.data.video_hls_url : this.state.data.video_url;
        
        if( !url ) return null;
        const video = url.split(".").pop() == 'm3u8' ?  {
            url,
            type: 'customHls',
            customType: {
                customHls: function (video, player) {
                    const hls = new HlsJs();
                    hls.loadSource(video.src);
                    hls.attachMedia(video);
                }
            },
        }:{url};

        // console.log( video, url.split(".").pop()  );

    return <div className="section-video">
         {/* {this.state.data} */}
        <DPlayer
            options={{
                autoplay:true,
                video,
                contextmenu:[]
            }}
            onEnded={
                //()=>{this.setState({"data":false});this.props.onEnded()}
                ()=>{this.props.onEnded()}
            }
        />
    </div>;
    }
}