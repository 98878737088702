import React, { Component } from 'react';
import Hello from '../component/Hello';
import { inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { Button, Intent, Spinner } from '@blueprintjs/core';
import TopNotice from '../component/TopNotice'; 
import TopNav from '../component/TopNav'; 
import HeroCourse from '../component/HeroCourse'; 
import CateNav from '../component/CateNav'; 
import CateCourseList from '../component/CateCourseList'; 
import Footer from '../component/Footer'; 



@withRouter
@inject("store")
export default class Index extends Component
{
    state = {"info":false,"courses":[]};
    
    async componentDidMount()
    {
        // 获取 code 并回传到 api
        
        const info = await this.props.store.get_course_info();
        if( info )
        {
            this.setState({info});
        }

        await this.loadCourse();
    }

    componentDidUpdate( prevProps )
    {
        if( this.props.match.params.cate !=  prevProps.match.params.cate)
        this.loadCourse();
    }

    async loadCourse()
    {
        // 根据 cate 读取课程列表
        const courses = await this.props.store.get_course_list( this.props.match.params.cate );
        this.cate = this.props.match.params.cate;
        this.setState({courses});
        console.log("loading");
    }

    render()
    {
        const main = <div>
        <TopNotice />
        <TopNav/>
        { this.state.info && this.state.info.hero && <HeroCourse data={this.state.info.hero} />  }
        { this.state.info && this.state.info.cates && <CateNav data={this.state.info.cates} />  }
        
        <CateCourseList data={this.state.courses} />
        <Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
} 