import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import TopNav from '../component/TopNav'; 
import MyReactMarkdown from '../component/MyReactMarkdown'; 
import { toast } from '../util/Function';
import Footer from '../component/Footer';

@withRouter
@inject("store")
@observer
export default class Post extends Component
{
    state = {"post":false};
    
    async componentDidMount()
    {
        await this.loadPost();
    }

    async componentDidUpdate( prevProps )
    {
        if( this.props.match.params.route !=  prevProps.match.params.route)
        await this.loadPost();
    }

    async loadPost()
    {
        const route = this.props.match.params.route; 
        if( route )
        {
            const post = await this.props.store.get_post_detail( route );
            if( post ) this.setState({post});
            else toast("内容不存在或已被删除");
        } 
    }

    render()
    {
        const main = this.state.post ? <div>
            <TopNav />
            <div className="page-box">
                <div className="ft-post-page">
                    <h2 className="rs-title">{this.state.post.title}</h2>
                    <div className="whitebox">
                    <MyReactMarkdown source={this.state.post.markdown} />
                    </div> 
                </div>
                          
            </div>
            <Footer/>
        </div>:null;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}