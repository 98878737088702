import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FormGroup, Button, Intent } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class SubmitLine extends Component
{
    render()
    {
        return <><div className="std-hr form-div"/><FormGroup>
        <div className="row-half">
            <div className="left">
    <Button large="true" intent={Intent.PRIMARY} onClick={()=>this.props.onSubmit()}>{this.props.label||"提交"}</Button>
            </div>
            <div className="right">

            {this.props.right? this.props.right : <Button large="true" onClick={()=>{
                if( this.props.onCancel ) 
                    this.props.onCancel(); 
                else 
                    this.props.history.goBack();
            }} className="right-20">取消</Button> }
            

                
            </div>
        </div>
        
        </FormGroup></>;
    }
}