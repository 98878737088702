import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Icon } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class CourseSectionTopTitle extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        const section = this.props.data;
        if( !section ) return null;

        return <div className={"section-top-title ft-bg-"+section.course.color}>
            <div className="container pointer" onClick={()=>window.location='/course/detail/'+section.course.id}>
            {section.course.title} <Icon icon="chevron-right"/> {section.title}
            </div>
            </div>;
    }
}