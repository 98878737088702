import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import CourseCard from '../component/CourseCard'; 

@withRouter
@inject("store")
@observer
export default class CateCourseList extends Component
{
    
    render()
    {
        return <div className="cate-course-list">
        <div className="container">
            { this.props.data && this.props.data.map( item => 
                <CourseCard show_price={true} key={item.id} data={item} />
             ) }
        </div>
    </div>;
    }
}