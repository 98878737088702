import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import logo from '../resource/logo.svg';
import UserMenu from '../component/UserMenu'; 
import UserAvatar from '../component/UserAvatar'; 
import UserName from '../component/UserName'; 
import TopMenu from '../component/TopMenu'; 
import SmallMenu from '../component/SmallMenu'; 


@withRouter
@inject("store")
@observer
export default class TopNav extends Component
{
    state = {"small_mode":false};
    
    componentDidMount()
    {
       this.setSmallMode();
       window.addEventListener( "resize" , this.setSmallMode.bind(this));
    }

    componentWillUnmount()
    {
        window.removeEventListener( "resize", this.setSmallMode.bind(this) );
    }

    setSmallMode()
    {
        if( window.innerWidth <= 600 ) this.setState({"small_mode":true});
        else this.setState({"small_mode":false});
        
    }

    async authpop()
    {
        await  this.props.store.force_login();
    }
    
    render()
    {
        return <div className="ft-top-nav" >
        <div className="container">
        {
            this.state.small_mode ? 
            <>
            <div className="left-menu"><div className="logo" onClick={()=>this.props.history.push("/")}>
            <img src={logo} alt="logo" />
            </div></div>
            
            <div className="right-menu">
                <SmallMenu authpop={()=>this.authpop()}/>
            </div>
            </>
            :
            <><div className="left-menu">
                <div className="logo-box" onClick={()=>this.props.history.push("/")}>
                    <img src={logo} alt="logo"/>
                </div>
                <TopMenu />
            </div>
            
            <div className="right-menu">
                { this.props.store.user ? 
                
                <><UserAvatar /><UserName/><UserMenu /></>

                : <Button text="微信登入" className="outline" icon="user" large={true} minimal={true} onClick={()=>this.authpop()}/>}
                
            </div>
            </>
        }
        
        

        </div>
        </div>;
    }
}