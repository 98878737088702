import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class CartItem extends Component
{
    state = {"info":false};
    
    async componentDidMount()
    {
        const id = this.props.id;
        if( id > 0 )
        {
            const info = await this.props.store.get_course_detail( id );
            this.setState({info});
            this.props.store.course_buy_info[info.course.id] = this.state.info.course;
        }
        
    }

    remove( id )
    {
        this.props.store.cancel_course( id );
    }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        return this.state.info ? <div className="cart-item">
            <div className="title" onClick={()=>window.open("/course/detail/"+this.state.info.course.id)}>{this.state.info.course.title}</div>
            <div className="price">{(this.state.info.course.price_cent/100).toFixed(2)}</div>
            <div className="actionbar">
                <Button icon="cross" minimal={true} onClick={()=>this.remove(this.state.info.course.id)}/>
            </div>
        </div>:null;
    }
}