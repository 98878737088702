import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Card, Elevation } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class RoadMapCard extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        // console.log( this.props.data );
        return this.props.data ? <Card className="roadmap-card" interactive={true} elevation={Elevation.TWO} onClick={()=>window.open(this.props.data.link,'_blank')}>
        <div className="cover ft-bg-red">
            <img src={this.props.data.cover} alt={this.props.data.title}/>
        </div>
    <div className="text">
        <div className="h1">{this.props.data.title}</div>
        <div>{this.props.data.desp}</div>
    </div>
    </Card> : null;
    }
}