import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class SmallCateTab extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    
    render()
    {
        const smallMenu = (
            <Menu>
                <MenuItem text="全部课程" onClick={()=>this.props.history.push("/course")} />
                
                {this.props.data && this.props.data.map( item => <MenuItem text={item.text} key={item.link} onClick={()=>this.props.history.push(item.link)} /> )}
               
                
            </Menu>
        );
        
        return <div><Popover content={smallMenu} position={Position.BOTTOM}>
        <Button rightIcon="caret-down" minimal={true} text="课程分类" large={true}/>
        </Popover></div>;
    }
}