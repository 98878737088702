import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Index from './screen/Index';
import Login from './screen/Login';
import Redirect from './screen/Redirect'; 
import AuthBack from './screen/AuthBack'; 
import Logout from './screen/Logout'; 
import CourseDetail from './screen/CourseDetail'; 
import CourseSectionShow from './screen/CourseSectionShow'; 
import Post from './screen/Post'; 
import RoadMap from './screen/RoadMap'; 
import Cart from './screen/Cart'; 
import MyCourse from './screen/MyCourse'; 
import OrderCheck from './screen/OrderCheck'; 
import Recharge from './screen/Recharge'; 
import Subscribe from './screen/Subscribe'; 
import PromoLanding from './screen/PromoLanding'; 
import Recent from './screen/Recent'; 
import Cic from './screen/Cic'; 
import ProcessList from './screen/ProcessList'; 
import ProcessDetail from './screen/ProcessDetail'; 
import FinishDiscount from './screen/FinishDiscount'; 
import Share from './screen/Share'; 
import NoticePage from './screen/NoticePage'; 


import WechatLoginCallback from './screen/WechatLoginCallback'; 

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/mplogin/back" component={WechatLoginCallback} />
          <Route path="/:route1/:route.post" component={Post} />
          <Route path="/:route.post" component={Post} />

          <Route path="/share/:id" component={Share} />
          <Route path="/finish-discount/:id" component={FinishDiscount} />
          <Route path="/process/:id" component={ProcessDetail} />
          <Route path="/process" component={ProcessList} />
          <Route path="/:promoid.code" component={PromoLanding} />
          <Route path="/order/check" component={OrderCheck} />
          <Route path="/auth/back" component={AuthBack} />
          <Route path="/recent" component={Recent} />
          <Route path="/mylist" component={MyCourse} />
          <Route path="/subscribe" component={Subscribe} />
          <Route path="/recharge/:password?" component={Recharge} />
          <Route path="/cic" component={Cic} />
          <Route path="/cart" component={Cart} />
          <Route path="/logout" component={Logout} />
          <Route path="/roadmap" component={RoadMap} />
          <Route path="/section/show/:id" component={CourseSectionShow} />
          <Route path="/course/detail/:id/:uid?" component={CourseDetail} />
          <Route path="/course/:cate?" component={Index} />
          <Route path="/" component={NoticePage} />
          {/* <Route path="/" component={Redirect} /> */}
        </Switch>
      </Router>  
    );
  }
}

export default App;