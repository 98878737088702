import React, { Component } from 'react';
import { FormGroup, InputGroup } from "@blueprintjs/core";
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';


@withRouter
@inject("store")
@observer
export default class Login extends Component
{
    state = {"input":""};
    
    checkInput( e )
    {
        const v = e.target.value;
        if( v == 'ketu1980' )
        {
            window.location = '/course';
        }
        this.setState({"input":v});
    
    }

    render()
    {
        return <div className="fullcenter"><FormGroup
        label="请输入内测邀请码"
        >
        <InputGroup style={{"width":"300px","marginTop":"30px"}} placeholder="课兔内测邀请码" large="true" onChange={(e)=>this.checkInput(e)} type="password" value={this.state.input} autoFocus/>
        </FormGroup></div>;
    }
} 