import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import SmallCateTab from '../component/SmallCateTab'; 

@withRouter
@inject("store")
@observer
export default class CateNav extends Component
{
    state = {"small_mode":false};
    
    componentDidMount()
    {
       this.setSmallMode();
       window.addEventListener( "resize" , this.setSmallMode.bind(this));
    }

    componentWillUnmount()
    {
        window.removeEventListener( "resize", this.setSmallMode.bind(this) )
    }

    setSmallMode()
    {
        if( window.innerWidth <= 600 ) this.setState({"small_mode":true});
        else this.setState({"small_mode":false});
        
    }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        // console.log( this.props.data )
        const cate = this.props;
        return <div className="cate-tab">
        <div className="container ">
            { this.state.small_mode ? <SmallCateTab data={cate.data}/> : <ul>
                <li><NavLink exact to="/course">全部课程</NavLink></li>
                { cate.data.map( item =>
                    {
                    return <li key={item.link+'-'+item.text}><NavLink to={item.link}>{item.text}</NavLink></li>
                } ) }
            </ul>}
        </div>
        </div>;
    }
}