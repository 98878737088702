import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
@withRouter
@inject("store")
@observer
export default class UserName extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
    return this.props.store.user && this.props.store.user.nickname?<div className="userbox"><span className="avatar-name">{ this.props.store.user.nickname }</span>{this.props.store.user.coin > 0 && <><Icon icon="dollar"/>{this.props.store.user.coin}</>}</div>:null;
    }
}