import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Footer from '../component/Footer'; 
import TopNav from '../component/TopNav'; 
import { toast } from '../util/Function';
import { Button } from '@blueprintjs/core';
import {CopyToClipboard} from 'react-copy-to-clipboard';

@withRouter
@inject("store")
@observer
export default class Cic extends Component
{
    state = {"cic_key":false,"show_code":false};
    
    async componentDidMount()
    {
        
        await this.load_cic_key();
    }

    async load_cic_key()
    {
        const ret = await this.props.store.get_cic_key();
        if( ret && ret.cic_key )
        {
            this.setState({"cic_key":ret.cic_key})
        }
        

    }

    render()
    {
        const eye_icon = this.state.show_code ? 'eye-off':'eye-open';
        
        const main = <div><TopNav/>
            <div className="page-box">
                <div className="ft-post-page">
                
                    <h2 className="rs-title">领取互动课</h2>
                    <p>为了方便复制代码和先跳过繁杂的环境配置，我们采用了互动式教学来教授编程实践内容。</p>
                    <p>请在复制领课码后在课猫云平台上学习。</p>
                    <div className="whitebox small big-font">
                    {this.state.cic_key && this.state.cic_key.length > 0 ? <div className="promo-code">
               🐱&nbsp; <span className="code">{this.state.show_code?this.state.cic_key:"****************"}</span>
               
               <Button icon={eye_icon} large={true} className="mr5" onClick={()=>this.setState({"show_code":!this.state.show_code})} />
               <CopyToClipboard text={this.state.cic_key} onCopy={()=>
                {
                    if(window.confirm("已经复制到剪贴板，是否打开课猫云互动课平台领取课程。"))
                    {
                        window.open("http://vip.catinclass.com/student/lesson","_blank");
                    }
                }}><Button text="复制互动课领课码" large={true} className="mr5" /></CopyToClipboard> 
                </div> : "只有订阅用户才能领取互动课，请订阅后再访问此页面。" }
                    </div>

                <h2 className="rs-title">领课码输入位置</h2>
                <div className="whitebox small big-font maximg">
                <img src="https://theseven.ftqq.com/20200623233455.png
"/>
                </div>    

                    
                
                </div>
            </div><Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}