import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Footer from '../component/Footer'; 
import TopNav from '../component/TopNav';
import { Button,Checkbox, Tooltip } from '@blueprintjs/core';
import { toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class FinishDiscount extends Component
{
    state = {"sections":false,"course":false,"all_done":false,"donate":true};
    
    async componentDidMount()
    {
        const course_id = this.props.match.params.id; 
        if( course_id > 0 )
        {
            const data = await this.props.store.get_course_process( course_id );

            if( data )
            {
                this.setState({...data});
            }       
        }
    }
    async refund( course_id, type )
    {
        if( type=='all' && !window.confirm( "确定要退款么，退款后将不再可以学习，且失去以特价进行购买的机会" )) return false;

        const data = await this.props.store.refund_course( course_id , type , this.state.donate ? 1 : 0 );

        if( data )
        {
            if( data.is_done == 1 )
            {
                toast(data.info);
                this.props.history.push("/share/"+this.props.match.params.id);
            }
            else
            {
                toast(data.info);
            }
        }

    }

    render()
    {
        const course = this.state.course;
        

        const main = <div>
        <TopNav/>
        <div className="container ft-notop">
            {
                this.state.all_done != 1 ? <div className="notice-box">课程还未学习完成，不能进行退款 <Link to={"/process/"+course.id}>点这里查看课程进度</Link></div>
                :
                <div className="discount-box">
                    <div className="big-title">恭喜学完课程</div>
                    <div className="desp">
                        现在你获得了一个以特别划算的价格<strong>单独购买</strong>此课程的机会。
                    </div>
                    <div className="center-box">
                        <div className="price-box">
                            <div className="now-price">
                                ¥<span>{(this.state.discount/100).toFixed(2)}</span>
                            </div>
                            <div className="old-price">
                            <del>¥<span>{(course.price_cent/100).toFixed(2)}</span></del>
                            </div>
                            
                        </div>
                        <div className="action-box">
                            <Button text="以此价格购买" large={true} intent="primary" onClick={()=>this.refund(course.id, 'buy')}/>
                            <div className="explain">
                                将会自动退还差价
                            </div>
                        </div>
                        <div className="std-hr"></div>
                        <Button text="申请退款" large={true} onClick={()=>this.refund(course.id, 'all')}/>
                        <div className="mt-2 text-gary-300">
                        <Tooltip content="服务器流量高达0.8元/G，每次学习都会消耗不少流量，您的赞助将让我们有更多流量/带宽来上架新课程" >
                        <Checkbox checked={this.state.donate} label="赞助1元，补上流量费，帮助我们上架更多课程" onChange={()=>this.setState({"donate":!this.state.donate})} />
                        </Tooltip>
                        
                        </div>
                        <div className="explain">
                            ⚠️ 退款后将不能再次学习本课程
                        </div>
                        

                    </div>
                    
                </div>
            }
            
        </div>
            <Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}