import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Overlay, Icon  } from '@blueprintjs/core';
import {  toast, isWechat } from '../util/Function';
import QRImage from '../component/QRImage';

@withRouter
@inject("store")
@observer
export default class BuyButton extends Component
{
    state = {"qr_url":false};
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }

    async buy( course_id )
    {
        this.props.history.push('/');
        toast("本站已停止新用户注册，请访问新版课程网站");
        return false;

        if( !isWechat() )
        {
            // toast("请用微信扫码支付");
            this.setState({"qr_url":window.location.href});
            return false;
        }

        if(await  this.props.store.force_login())
        {
            const ret = await this.props.store.set_order( course_id );
            
            if( !ret ) return false;


            const jsinfo = typeof ret ===  'object' ? ret : JSON.parse( ret );

            console.log( "jsinfo" , jsinfo );

            if( jsinfo && jsinfo.appId )
            {
                console.log( "jsinfo in" );
                // 通过 js bridge 唤起微信支付
                // 定义回调函数
                let that = this;

                // 定义回调函数
                function onBridgeReady() 
                {
                    console.log( "on bridge" );
                    /*global WeixinJSBridge:true*/
                    /*eslint no-undef: "error"*/
                    WeixinJSBridge.invoke(
                        'getBrandWCPayRequest', {
                            "appId":jsinfo.appId,     //公众号名称，由商户传入     
                            "timeStamp":jsinfo.timeStamp,         //时间戳，自1970年以来的秒数     
                            "nonceStr":jsinfo.nonceStr, //随机串     
                            "package":jsinfo.package,     
                            "signType":"MD5",         //微信签名方式：     
                            "paySign":jsinfo.paySign //微信签名 
                        },
                        async (res)=>{
                        if(res.err_msg == "get_brand_wcpay_request:ok" )
                        {
                            // 这里要用 that 调用
                            toast("支付成功");
                            that.props.history.push("/process");
                        }else
                        {
                            // alert( JSON.stringify(res) );
                        } 
                    }); 
                }

                if (typeof WeixinJSBridge == "undefined")
                {
                    console.log( "WeixinJSBridge undefined" )

                    if( document.addEventListener ){
                        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                    }else if (document.attachEvent){
                        document.attachEvent('WeixinJSBridgeReady', onBridgeReady); 
                        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                    }
                }
                else
                {
                    console.log( "load bridge" )
                    onBridgeReady();
                }


                
                // console.log( jsinfo );
            }
        }

        




    }
    
    render()
    {
        return <><Button text={this.props.text||""} large={true} minimal={true} icon="offline" onClick={()=>this.buy(this.props.course_id)} className="outline white"/>
        <Overlay isOpen={this.state.qr_url}>
        
        <div className="overbox">
        <div className="section-qr">
            <div className="close-button" onClick={()=>this.setState({"qr_url":false})}><Icon icon="cross" iconSize={20} /></div>
            
            <div className="white-box">
                <QRImage value={this.state.qr_url} className="qrimg-box"  /> 
                <div className="subtitle">请用微信扫码支付</div>
            </div>
            
            
        </div>
        </div>
        
        </Overlay>
        </>;
    }
}