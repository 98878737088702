import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { Spinner } from '@blueprintjs/core';

@withRouter
@inject("store")
@observer
export default class AuthBack extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    async componentDidMount()
    {
        // 获取 code 并回传到 api
        const code = new URLSearchParams(this.props.location.search).get('code');
        if( code )
        {
            if( await this.props.store.login_by_code( code ) )
            {
                if( window.opener )
                {
                    window.opener.location.reload();
                    window.close();
                }
                else
                {
                    window.location = '/';
                }
                
            }
        }
    }

    render()
    {
        const main = <div className="fullcenter">
            <Spinner/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}