import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Footer from '../component/Footer'; 
import TopNav from '../component/TopNav'; 
import CourseCard from '../component/CourseCard'; 

@withRouter
@inject("store")
@observer
export default class Recent extends Component
{
    state = { "courses":false };
    
    async componentDidMount()
    {
       const courses = await this.props.store.get_recent_course_list();
       if( courses )  this.setState({courses});
    }
    
    // componentDidMount()
    // {
    //    // 
    // }

    render()
    {
        const main = <div>
            <TopNav/>
            { !this.state.courses ? 
            <div className="container ft-notop">
                <div className="notice-box">还没有开始学习呢，<Link to="/course">赶紧开始学习吧</Link></div>
                
            </div>
            : 
                <div className="cate-course-list">
                <div className="container top50">
                    {this.state.courses.map( item =>  <CourseCard key={item.id} data={item} /> )}
                </div></div>
            }
        <Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}