import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import TopNav from '../component/TopNav'; 
import CartItem from '../component/CartItem'; 
import { Button, Overlay, Icon } from "@blueprintjs/core";
import QRImage from '../component/QRImage';

@withRouter
@inject("store")
@observer
export default class Cart extends Component
{
    state = {"qr_url":false};
    
    // componentDidMount()
    // {
    //    // 
    // }
    async buyall()
    {
        // 构建 wa 支付页面的 url 和 参数
        const ret = await this.props.store.get_buy_url();
        //console.log( ret );
        if( ret )
        {
            this.setState( {"qr_url":ret} );
            // 生成并显示二维码
            //this.props.store.clean_cart();
            //this.props.history.push("/mylist");
        } 
    }

    fin()
    {
        this.props.store.clean_cart();
        this.props.history.push("/mylist");
    }

    render()
    {
        const main = <div><TopNav/>
        <div className="ft-notop container">
        { this.props.store.course_to_buy && 
        ( this.props.store.course_to_buy.length > 0 ? 
        <>
            {this.props.store.course_to_buy.map( id => 
                <CartItem id={id} key={id}/>
            )}

            <div className="cart-item flex-right">
                <div className="actionbar">
                    <div className="bottom20">合计 { (this.props.store.get_buy_total_price()/100).toFixed(2) } 元</div>
                    <Button icon="mobile-phone" text="使用微信扫码支付" onClick={()=>this.buyall()} large={true} /></div>
            </div>
        </> : <div className="cart-item">
                <div className="actionbar">
                    还没有要购买的课程，<Link to="/course">先去添加吧</Link>。
                </div>
            </div>  )
        
         }
        
        </div>

        <Overlay isOpen={this.state.qr_url}>
        
        <div className="overbox">
        <div className="section-qr">
            <div className="close-button" onClick={()=>this.setState({"qr_url":false})}><Icon icon="cross" iconSize={20} /></div>
            
            <QRImage value={this.state.qr_url} className="qrimg-box"  /> 
            <div>
                <Button large={true} text="完成支付后点我" className="top-10" onClick={()=>this.fin()} />
            </div>
            
        </div>
        </div>
        
        </Overlay>
        
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}