import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';

@withRouter
@inject("store")
@observer
export default class WechatLoginCallback extends Component
{
    async componentDidMount()
    {
        // 获取 code 并回传到 api
        const code = new URLSearchParams(this.props.location.search).get('code');
        if( code )
        {
            if( !await this.props.store.login_by_mpcode(code) )
            {
                // 自动登入失败转向显式授权登入页面
                // this.props.history.replace("/auth/mlogin");
                window.location = await this.props.store.get_weauth_url();
            }
            else
            {
                // 自动登入成功，转向已购页面
                // this.props.history.replace("/course/paid");
                const url = window.localStorage.getItem('forward');
                window.localStorage.removeItem( 'forward' );
                this.props.history.replace(  url || '/mylist' );
            }
            // console.log();
        }
    }

    render()
    {
        return <div className="fullcenter">
            <Spinner/>
        </div>;
    }
}