import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class HeroRoadMap extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        return <><div className="ft-hero-div">
        <div className="container">
            <div className="hero-box ft-bg-red level1">
            <img src={this.props.data.img} alt="推荐路线图" onClick={()=>window.open(this.props.data.url,'_blank')}/>
            </div> 
        </div>
    </div></>;
    }
}