import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Footer from '../component/Footer'; 
import TopNav from '../component/TopNav'; 
import { Button, InputGroup } from '@blueprintjs/core';
import TextLine from '../component/TextLine';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { saveAs } from 'file-saver';
import { toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class Share extends Component
{
    state = {"info":false,"copy":false};
    
    async componentDidMount()
    {
        const id = this.props.match.params.id; 
        if( id > 0 )
        {
            const info = await this.props.store.get_course_detail( id );
            if( info ) this.setState({info});
            
            this.props.store.the_share_text = '刚刚在「方糖完免课」学完了《'+ info.course?.title +'》，课程还不错，最爽的是按时学完可以免费，要不要也来试一下 →  https://mian.ftqq.com/course/detail/'+info.course?.id+'/'+this.props.store.user?.uid;

            // window.setTimeout( ()=>this.checkLastPlay(id) , 3*1000 );
        }
    }

    async check( course_id, uid )
    {
        const ret = await this.props.store.check_resoure_download( course_id, uid );

        if( ret && ret.url )
        {
            toast("即将开始正在下载...");
            saveAs( ret.url ); 
        }
    }

    render()
    {
        const course = this.state.info?.course;
        if( !course ) return null;
        const main = <div>
        <TopNav/>
        <div className="share-box">
        {course?.download_name?.length> 0 ? <>
            <h3>如果你觉得我们的课程不错，可以把它分享给朋友 🐱</h3>
            分享链接被点击后，可以下载我们整理的学习资料： <Button icon="box" minimal={true}>{course.download_name}</Button>

            <TextLine  field="the_share_text" type="textarea" />

            <div className="btnline">
            <CopyToClipboard text={this.props.store.the_share_text} onCopy={()=>this.setState({"copy":true})}><Button className="mr-2" large={true} text="复制推荐链接" /></CopyToClipboard>
            
             { this.state.copy && <Button  large={true} text="分享后，点此下载" onClick={()=>this.check( course.id , this.props.store.user?.uid )}/> }
            </div>
            
            
        </> : <>
            此课程没有学习资料
        </> }
        </div>
        
        
    </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}