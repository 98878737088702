import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import * as sd from 'simple-duration';
import { Icon, Button } from '@blueprintjs/core';

@withRouter
@inject("store")
@observer
export default class ProcessItem extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        const section = this.props.data;
        if( !section ) return null;

        // if( spent > section.duration/3 )
        // {
        //     console.log( spent , section.duration/3 )
        // }

        // console.log( Math.round(new Date(section.last_at).getTime()/1000),Math.round(new Date(section.start_at).getTime()/1000) , learn );

        return <div className="whiteitem">
            <div className="left">
                <span className="thetitle">{section.title}</span> 
                <span className="duration"> {sd.stringify(parseInt(section.duration), "s")}</span>
                <span className="check-icon">
                { section.is_finished ? <Icon icon="tick-circle" className="done"/> : <Icon icon="time" /> }
                
                </span>
                
            </div>
            <div className="right">
                <Button text="去学习" icon="chevron-right" large={true} onClick={()=>this.props.history.push("/section/show/"+section.id)}/>
            </div>
        </div>;
    }
}