import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import MyReactMarkdown from '../component/MyReactMarkdown'; 


@withRouter
@inject("store")
@observer
export default class CourseSectionMarkdown extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        const markdown = this.props.data;
        if( !markdown ) return null;
        return <div className="container section-markdown">
            <MyReactMarkdown source={markdown} />
            
        </div>;
    }
}